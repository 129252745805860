import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index.vue'),
  },
  {
    path: '/index',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta:{
      requireAuth:true,
    },
    children:[
      {
        path: '/user',
        component: () => import('@/views/user/Index.vue'),
      },
      {
        path: '/show',
        component: () => import('@/views/show/Index.vue'),
      },
      {
        path: '/scene',
        component: () => import('@/views/scene/Index.vue'),
      },
      {
        path: '/favourite',
        component: () => import('@/views/favourite/Index.vue'),
      },
      {
        path: '/jsmind',
        component: () => import('@/views/jsmind/Index.vue'),
      },
      {
        path: '/chatgpt',
        component: () => import('@/views/chatgpt/Index.vue'),
      },
    ]
  },
  // {
  //   path: '/',
  //   redirect: '/login'
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆

router.beforeEach((to, from, next) => {
  if(to.path === '/login') {
    // console.log('67',to,from);
    // if(to.fullPath.indexOf('/?room_id=') != -1){
    //   localStorage.setItem('goroom',to.fullPath)
    // }
    return next()
  }
  let token = sessionStorage.getItem('token');
  let localtoken = localStorage.getItem('token');
  // console.log(to,to.matched.some(record => record.meta.requireAuth));
  if(to.matched.some(record => record.meta.requireAuth)){
    if (token === null || token === '') {
      // console.log('3',token,from,to);
      // if(to.fullPath.indexOf('/?room_id=') != -1){
      //   sessionStorage.setItem('goroom',to.fullPath)
      // }
      next('/login');
    } else {
      // console.log('4',token,from,to);
      next()
    }
  } else {
    console.log(localtoken,localtoken === null || localtoken === '');
    if (localtoken === null || localtoken === '') {
      // console.log('else无token',to.fullPath);
      if(to.fullPath.indexOf('/?room_id=') != -1){
        // debugger
        sessionStorage.setItem('goroom',to.fullPath)
        setTimeout(()=>{
          sessionStorage.setItem('goroom',to.fullPath)
        },1000)
        // localStorage.setItem('goroom',to.fullPath)
      }
      next('/login');
    }else{
      //https://meta.xycn.cc/?room_id=19741968
      //http://localhost:8080/?room_id=19741968&exit=1
      if(to.fullPath.indexOf('/?room_id=') != -1){
        if(to.fullPath.indexOf('exit=1') != -1){
          localStorage.removeItem('goroom');
          sessionStorage.removeItem('goroom');
          localStorage.removeItem('token');
          localStorage.removeItem("activePath")
          sessionStorage.removeItem('token');
          sessionStorage.removeItem("activePath")
          document.cookie = 'token='
          Cookies.set('token', '' , { domain: '.xycn.cc' })
          next('/login')
        }else{
          Cookies.set('token', `${localtoken}` , { domain: '.xycn.cc' })
          window.open(`https://scene.xycn.cc${to.fullPath}`,'_self')
          next()
        }
      }else{
        next('/login')
      }
    }
  }
});
// router.afterEach((to,from)=>{
//   if(from.path != '/login' && from.path != '/' && to.path == '/login'){
//       window.location.reload();
//   }
// })
export default router
