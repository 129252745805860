import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/global.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueParticles from 'vue-particles'
import VueClipBoard from 'vue-clipboard2'
// import './utils/flexible'
import 'lib-flexible'
//import jsCookie from 'js-cookie'
//Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用
Vue.use(VueClipBoard)
Vue.use(VueParticles)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')


