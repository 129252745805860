<template>
  <div id="app" ref="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'App',
   components: {},
  provide(){
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  cread() {},
  methods: {
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     const $app = this.$refs.app
  //     const standardScale = 1050 / 2560

  //     window.addEventListener(
  //       'resize',
  //       _.debounce(function () {
  //         const docHeight = document.body.clientHeight
  //         const docWidth = document.body.clientWidth
  //         if (docWidth < 2560) {
  //           const currentScale = docHeight / docWidth
  //           let [scale, translate] = [0, 0]
  //           if (currentScale < standardScale) {
  //             // console.log(currentScale < standardScale,docHeight,docWidth,'2560,1050');
  //             // 以高度计算
  //             scale = docHeight / 1155
  //             const shouleWidth = 2325 * scale
  //             const offsetWidth = docWidth - shouleWidth
  //             translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
  //           } else {
  //             // 以宽度计算
  //             // console.log('6');
  //             scale = docWidth / 2325
  //             const shouleHeight = 1155 * scale
  //             const offsetHeight = docHeight - shouleHeight
  //             translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
  //           }
  //           console.log(translate)
  //           $app.style.cssText = `
  //           transform: scale(${scale}) ${translate};
  //           transform-origin: top left;
  //           min-width: 2560px;
  //           min-height: 1050px;
  //         `
  //         } else {
  //           $app.style.cssText = ''
  //         }
  //       }),
  //       200
  //     )

  //     if (document.createEvent) {
  //       var event = document.createEvent('HTMLEvents')
  //       event.initEvent('resize', true, true)
  //       window.dispatchEvent(event)
  //     } else if (document.createEventObject) {
  //       window.fireEvent('onresize')
  //     }
  //   })
  // },
}
</script>
<style lang="scss">
#app{
  position: relative;
  width: 100vw;
  height: 100vh;
}
</style>
